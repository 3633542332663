<template>
    <div id="status-container" :class="[$mq]">
        <!-- APP VERSION & UPDATE -->
        <div class="section">
            <div class="section-title" :class="{ alert: compareVersions(serverVersion, appVersion) }">{{ $t('config.status.app_version') }}</div>
            <div class="two-columns">
                <div class="column">
                    <div class="label">{{ $t('config.status.current_version') }}</div>
                    <div class="value">{{ appVersion }}</div>
                </div>
                <div class="column">
                    <div class="label">{{ $t('config.status.available_version') }}</div>
                    <div class="value">{{ serverVersion }}</div>
                </div>
            </div>
            <div class="action">
                <div class="button" v-if="compareVersions(serverVersion, appVersion)" @click="updateApp()">{{ $t('popup.update.text_download') }}</div>
            </div>
        </div>

        <!-- INTERNET SPEED -->
        <div class="section">
            <div class="section-title" :class="{ alert: !isOnline || internetSpeed < 1 }">{{ $t('config.status.internet_connection') }}</div>
            <div class="two-columns">
                <div class="column">
                    <div class="label">{{ $t('config.status.connection_status') }}</div>
                    <div class="value">{{ isOnline ? 'Online' : 'Offline' }}</div>
                </div>
                <div class="column">
                    <div class="label">{{ $t('config.status.connection_speed') }}</div>
                    <div class="value">{{ internetSpeed | translateSpeed }}</div>
                </div>
            </div>
            <div class="action">
                <template v-if="!performingTest">
                    <div class="description" v-if="!isOnline" v-html="$t('config.status.no_connection_description')"></div>
                    <div class="description" v-else-if="internetSpeed != '-' && (internetSpeed > 1 || internetSpeed == '> 10')" v-html="$t('config.status.fast_connection_description')"></div>
                    <div class="description" v-else v-html="$t('config.status.slow_connection_description')"></div>
                    <div class="button light" @click="speedTest()">{{ $t('config.status.start_test') }}</div>
                </template>

                <div v-else class="test">
                    <div class="label">{{ $t('config.status.performing_test') }}</div>
                    <div class="loader"></div>
                </div>
            </div>
        </div>

        <!-- SYNCRONIZE -->
        <div class="section" :key="changesToSyncronize">
            <div class="section-title" :class="{ alert: changesToSyncronize > 0 }">{{ $t('config.status.syncronization') }}</div>
            <div class="action">
                <div class="description" v-if="changesToSyncronize > 0" v-html="$t('config.status.syncronization_description', { s: changesToSyncronize })"></div>
                <div class="description" v-else v-html="$t('config.status.syncronization_completed')"></div>
                <div class="button" v-if="changesToSyncronize > 0" @click="syncronize()">{{ $t('config.status.syncronize_now') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            internetSpeed: '-',
            isOnline: false,
            changesToSyncronize: 0,
            performingTest: false,
            slowConnectionTimer: false
        }
    },
    computed: {
        serverVersion() {
            return this.$store.getters['getServerVersionCurrent']
        },
        appVersion() {
            return process.env.VUE_APP_VERSION
        }
    },
    methods: {
        compareVersions(v1, v2) {
            return compareVersions(v1, v2)
        },
        getChangesToSyncronize() {
            var self = this
            return this.$store.dispatch('getCountSyncItems').then(count => {
                self.changesToSyncronize = count
                self.$overlay.hide()
                return true
            })
        },
        speedTest() {
            this.performingTest = true
            this.isOnline = fnCheckConnection()

            var startTime, endTime, calcStartTime
            var self = this

            var testConnectionSpeed = {
                // imageAddr: 'https://alexapi.labtowin.com/AndyInternetSpeedBig.png',
                // downloadSize: 67261192, // bytes
                imageAddr: process.env.VUE_APP_SPEED_TEST_IMG,
                downloadSize: 16420860, // bytes
                run: function(mbps_max, cb_gt, cb_lt) {
                    testConnectionSpeed.mbps_max = parseFloat(mbps_max) ? parseFloat(mbps_max) : 0
                    testConnectionSpeed.cb_gt = cb_gt
                    testConnectionSpeed.cb_lt = cb_lt
                    testConnectionSpeed.InitiateSpeedDetection()
                },
                InitiateSpeedDetection: function() {
                    window.setTimeout(testConnectionSpeed.MeasureConnectionSpeed, 0)
                },
                result: function(finished = true) {
                    clearTimeout(self.slowConnectionTimer)
                    if (finished) {
                        var duration = (endTime - (calcStartTime ? calcStartTime : startTime)) / 1000
                        var bitsLoaded = testConnectionSpeed.downloadSize * 8
                        var speedBps = (bitsLoaded / duration).toFixed(2)
                        var speedKbps = (speedBps / 1024).toFixed(2)
                        var speedMbps = (speedKbps / 1024).toFixed(2)
                        self.$store.dispatch('employee/sendSpeedTestResult', { velocity: speedMbps })

                        if (speedMbps >= (testConnectionSpeed.mbps_max ? testConnectionSpeed.mbps_max : 1)) {
                            testConnectionSpeed.cb_gt ? testConnectionSpeed.cb_gt(speedMbps) : false
                        } else {
                            testConnectionSpeed.cb_lt ? testConnectionSpeed.cb_lt(speedMbps) : false
                        }
                    } else {
                        testConnectionSpeed.cb_lt('-')
                    }
                },
                MeasureConnectionSpeed: function() {
                    var download = new Image()
                    download.onload = function() {
                        endTime = new Date().getTime()
                        testConnectionSpeed.result()
                    }
                    startTime = new Date().getTime()
                    var cacheBuster = '?nnn=' + startTime
                    download.src = testConnectionSpeed.imageAddr + cacheBuster
                    setTimeout(function() {
                        calcStartTime = new Date().getTime()
                    }, 1500)
                    self.slowConnectionTimer = setTimeout(() => {
                        if (self.performingTest) {
                            testConnectionSpeed.result(false)
                        }
                    }, 60000)
                }
            }

            // start test immediatly, you could also call this on any event or whenever you want
            testConnectionSpeed.run(
                1.5,
                function(mbps) {
                    self.internetSpeed = mbps
                    self.performingTest = false
                },
                function(mbps) {
                    self.internetSpeed = mbps
                    self.performingTest = false
                }
            )
        },
        updateApp() {
            var self = this

            if (this.$store.getters.getIsApp) {
                const app_android = this.$store.getters['login/getAppAndroid']

                if (app_android && app_android.trim() != '') {
                    self.$overlay.loading()
                    if (app_android.trim().match(/\.apk/gi)) {
                        var fileTransfer = new FileTransfer()
                        fileTransfer.download(
                            app_android.trim(),
                            cordova.file.externalDataDirectory + 'andy.apk',
                            function(entry) {
                                log('download complete')
                                log(entry)
                                entry.file(function(file) {
                                    log(file)
                                    cordova.plugins.fileOpener2.open(cordova.file.externalDataDirectory + 'andy.apk', 'application/vnd.android.package-archive', {
                                        error: function(e) {
                                            log(e)
                                            self.$overlay.hide()
                                            window.open(app_android.trim(), '_system')
                                        },
                                        success: function() {
                                            self.$overlay.hide()
                                            log('file opened successfully')
                                        }
                                    })
                                })
                            },
                            function(error) {
                                const app_android = this.$store.getters['login/getAppAndroid']
                                log(error)
                                self.$overlay.hide()
                                window.open(app_android.trim(), '_system')
                            }
                        )
                    } else {
                        self.$overlay.hide()
                        window.open(app_android.trim(), '_system')
                    }
                } else {
                    self.$overlay.hide()
                    cordova.plugins.market.open(process.env.VUE_APP_ID_APP_ANDROID)
                }
            } else {
                window.location.reload()
            }
        },

        syncronize() {
            this.$overlay.loading()
            var self = this

            this.$store.dispatch('syncronize').then(function(response) {
                self.getChangesToSyncronize()
            })
        }
    },
    created() {
        if (fnCheckConnection) {
            this.isOnline = true
            if (!!window.chrome) {
                this.internetSpeed = navigator.connection.downlink == 10 ? '> ' + navigator.connection.downlink : navigator.connection.downlink
            } else {
                this.internetSpeed = '-'
            }
        }
        this.getChangesToSyncronize()
    },
    filters: {
        translateSpeed: function(speed) {
            if (fnCheckConnection()) {
                return speed + ' Mbps'
            } else return '-'
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#status-container {
    padding: 40px 40px 0 40px;

    width: auto;
    max-width: 800px;

    .section {
        @include display-flex();
        @include flex-direction(column);
        width: 100%;
        margin-bottom: 20px;

        .section-title {
            @include display-flex();
            @include align-items(center);
            @include background($image: img('checked_ok.svg'), $size: 20px, $position: left center);
            @include font-size(m);
            text-transform: uppercase;
            font-family: $text-bold;
            color: $neutro-s60;
            height: 25px;
            padding-left: 30px;
            margin-bottom: 20px;

            &.alert {
                @include background($image: img('circle_exclamation_accent.svg'), $size: 20px, $position: left center);
            }
        }

        .two-columns {
            @include display-flex();
            @include justify-content(space-between);
            @include align-items();
            margin-bottom: 20px;

            .column {
                @include display-flex();
                @include flex-direction(column);
                @include justify-content();
                @include align-items(flex-start);
                width: 50%;

                .label {
                    @include display-flex();
                    @include font-size(m);
                    @include justify-content();
                    @include align-items();
                    font-family: $text;
                    color: $neutro-s60;
                    margin-bottom: 5px;
                }

                .value {
                    @include font-size(m);
                    font-family: $text-bold;
                    color: $neutro-s60;
                    margin-bottom: 5px;
                }
            }
        }

        .action {
            @include display-flex();
            @include align-items();
            @include flex-direction(column);
            margin-bottom: 20px;

            .description {
                @include font-size(m);
                font-family: $text;
                color: $neutro-s60;
                width: 100%;
                margin-bottom: 15px;

                & > b {
                    color: $neutro-s60 !important;
                    font-family: $text-bold !important;
                }
            }

            .button {
                @include border-radius(4px);
                @include font-size(m);
                width: fit-content;
                padding: 10px 30px;
                font-family: $text-bold;
                color: $neutro-s60;
                background-color: $main;
                color: #fff;
                cursor: pointer;

                &:hover {
                    background-color: hover($main);
                }

                &.light {
                    background-color: $neutro;
                    color: $main;

                    &:hover {
                        background-color: hover($neutro);
                    }
                }
            }

            .test {
                .label {
                    @include font-size(s);
                    font-family: $text-bold;
                    color: $neutro-s60;
                    text-align: center;
                }

                .loader {
                    @include background($image: img('loader_main.svg'), $size: 50px, $position: center center);
                    margin-top: 10px;
                    width: 100%;
                    height: 30px;
                }
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#status-container.portrait {
    padding: 20px 10px 0;

    .section {
        margin-bottom: 30px;
        .section-title {
            @include font-size(s);
        }

        .two-columns {
            .column {
                .label {
                    @include font-size(xs);
                }

                .value {
                    @include font-size(sm);
                }
            }
        }

        .action {
            .description {
                @include font-size(xs);
            }

            .button {
                @include font-size(s);
                padding: 7px 30px;
            }

            .test {
                .label {
                    @include font-size(xs);
                }

                .loader {
                    margin-top: 5px;
                }
            }
        }
    }
}
</style>
