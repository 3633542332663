<template>
    <div v-if="ready" class="printer">
        <template v-if="!showForm">
            <p class="description">{{ $t('config.printer.config_title') }}</p>

            <!-- CHECK IF IT'S CHROME -->
            <template v-if="!isApp && !checkComputer">
                <Message :message="$t('config.printer.windows.noWindows')" type="error" :buttonLabel="$t('config.printer.windows.downloadAndy')" @buttonAction="gotoURL($store.getters['login/getAppAndroid'] ? $store.getters['login/getAppAndroid'] : 'market://details?id=com.intowin.alex')"></Message>
            </template>
            <template v-else-if="!isApp && !checkChrome">
                <Message v-if="!checkChrome" :message="$t('config.printer.windows.noChrome')" type="error" :buttonLabel="$t('config.printer.windows.downloadChrome')" @buttonAction="gotoURL('https://www.google.com/chrome')"></Message>
            </template>
            <!-- CHECK IF BPAC EXTENSION IS INSTALLED -->
            <template v-else-if="!isApp && !instaledBpac">
                <Message :message="$t('config.printer.errors.bpac_no_installed')" type="error" :buttonLabel="$t('config.printer.validate')" @buttonAction="reloadPage()"></Message>
            </template>

            <template v-else>
                <template v-if="Object.keys(config).length == 0">
                    <EmptyPage type="printer" @buttonAction="newConfig" :buttonText="$t('config.printer.add')" :title="$t('assets.overlay.timeout.message')" :description="$t('config.printer.no_printers_configured')"></EmptyPage>
                </template>

                <template v-else>
                    <!-- IF DEFAULT PRINTER EXISTS, RENDER FIRST -->
                    <template v-if="current_printer">
                        <Configuration :is_current_printer="true" :config="config[current_printer]" @delete="deleteConfig(current_printer)" @update="updateConfig(current_printer)"></Configuration>
                    </template>
                    <!-- ELSE REST OF PRINTER CONFIGS -->
                    <template v-for="printer in config">
                        <Configuration v-if="!current_printer || printer.id != current_printer" :is_current_printer="currentPrinter() ? printer.id == currentPrinter() : false" :config="printer" @delete="deleteConfig(printer.id)" @update="updateConfig(printer.id)" :key="printer.id"></Configuration>
                    </template>
                </template>
            </template>
        </template>

        <template v-else>
            <PrinterForm :is_new="new_configuration" @hideForm="hideForm()" @setFirstCurrent="setFirstPrinterCurrent()"></PrinterForm>
        </template>
    </div>
</template>

<script>
import Configuration from '@/components/domain/config/configuration'
import PrinterForm from '@/components/domain/config/printerForm'
import EmptyPage from '../../layout/EmptyPage.vue'
import Message from '@/components/domain/ui/Message'
import * as bpac from '@/../public/js/bpac.js'

export default {
    components: { Message, Configuration, PrinterForm, EmptyPage },
    props: { update: { type: Boolean, default: false }, config_id: { type: String, default: undefined } },
    data() {
        return {
            ready: false,
            showForm: false,
            new_configuration: false,
            instaledBpac: bpac.IsExtensionInstalled(),

            current_printer: false
        }
    },
    computed: {
        config() {
            var printers = this.$store.getters['printer/getPrinterConfig']()
            var oPrinters = {}
            for (var p in printers) {
                if (this.$store.getters.getIsApp && printers[p].platform == 'android') {
                    oPrinters[p] = printers[p]
                } else if (!this.$store.getters.getIsApp && printers[p].platform == 'windows') {
                    oPrinters[p] = printers[p]
                }
            }

            this.current_printer = this.$store.getters['printer/getCurrentPrinter']

            if (!this.current_printer || !this.$store.getters['printer/getPrinterConfig'](this.current_printer)) {
                this.current_printer = false
                for (var p in oPrinters) {
                    if (!this.current_printer) {
                        var pc = oPrinters[p]
                        if ((pc.platform == 'android' && this.$store.getters.getIsApp) || (pc.platform == 'windows' && !this.$store.getters.getIsApp)) {
                            this.current_printer = pc.id
                            this.$store.dispatch('printer/setCurrentPrinter', pc.id)
                        }
                    }
                }
            }
            log('Current Printer -> ' + this.current_printer)

            return oPrinters
        },

        isApp() {
            // return true
            return this.$store.getters['getIsApp']
        },

        checkComputer() {
            var OS = getOS()
            return OS == 'Windows' || OS == 'Mac OS' ? true : false
        },

        checkChrome() {
            let app = process.env.VUE_APP_IS_APP == 'TRUE'
            let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
            let isEdge = navigator.userAgent.indexOf('Edg') > 0

            // check if user is not in app and is not using google chrome, show error ...
            if ((!app && !isChrome) || isEdge) return false

            return true
        },

        isValidatebPac() {
            return this.$store.getters['printer/getIsValidatebPac']
        }
    },
    methods: {
        currentPrinter() {
            return this.$store.getters['printer/getCurrentPrinter']
        },

        reloadPage() {
            window.location.reload()
        },

        gotoURL(url) {
            if (url) window.open(url, '_blank')
        },

        validateBpac(config_id) {
            var self = this
            // self.$overlay.loading()
            this.$store.dispatch('printer/validateBpac', config_id).then(function(response) {
                // var msg = self.$t(response.error)
                // msg = msg.includes('[path]') && self.printer ? msg.replace('[path]', self.printer.path) : msg
                // self.$snackbar.error({ duration: 8000, closeable: true, message: msg })
                // self.$overlay.hide()
            })
        },

        validatePrinter() {
            var self = this
            // self.$overlay.loading()
            this.$store.dispatch('printer/statusPrinter').then(function(response) {
                // self.$overlay.hide()
            })
        },

        loadButtons() {
            var self = this
            if ((this.isApp || this.instaledBpac) && Object.keys(this.config).length != 0 && !(!this.isApp && !this.checkComputer)) {
                this.$bar.addAction('create', {
                    callback: () => {
                        self.newConfig()
                    },
                    label: self.$t('config.printer.add')
                })
            } else {
                this.$bar.reset()
            }
        },

        newConfig() {
            this.new_configuration = true
            this.$store.commit('printer/addNewPrinterConfig')
            this.showForm = true
        },

        setFirstPrinterCurrent() {
            if (typeof this.config[this.currentPrinter()] == 'undefined' && Object.keys(this.config).length > 0) {
                // SET NEW CURRENT PRINTER
                this.$store.commit('printer/setCurrentPrinter', Object.values(this.config)[0].id)
                // VALIDATE NEW CURRENT PRINTER
                if (!this.isApp) {
                    this.validateBpac(this.currentPrinter() ? this.currentPrinter() : false)
                } else {
                    this.validatePrinter()
                }
            }
        },

        deleteConfig(config_id) {
            var self = this
            this.$popup.confirm({
                message: self.$t('config.printer.delete_confirm', { s: self.config[config_id].name }),
                title: self.$t('popup.confirm.title'),
                textSave: self.$t('popup.confirm.text_save'),
                textCancel: self.$t('config.printer.cancel'),
                callSave: function() {
                    self.$overlay.loading()
                    self.$store.dispatch('printer/deletePrinterConfig', config_id).then(function() {
                        self.setFirstPrinterCurrent()
                        self.loadButtons()
                        self.$overlay.hide()
                    })
                }
            })
        },

        hideForm() {
            if (document.getElementsByClassName('content')[0]) document.getElementsByClassName('content')[0].scrollTop = 0
            this.showForm = false
        },

        updateConfig(config_id) {
            this.$store.commit('printer/setCurrentPrinterConfig', config_id)
            this.new_configuration = false
            this.showForm = true
        },

        // METHOD TO CLOSE MORE OPTIONS
        toggleBoxes(e) {
            // IF USER NOT CLICKS ON "3 DOT MORE" BUTTONS CLOSE ALL ACTIVE "MORE-OPTIONS"
            if (!e.target.className.includes('btn more')) {
                var divs = document.getElementsByClassName('more-options active')
                for (var i = 0; i < divs.length; i++) {
                    divs[i].classList.remove('active')
                }
            }
        }
    },

    created() {
        document.addEventListener('click', this.toggleBoxes)
        var self = this
        this.$overlay.loading()
    },
    mounted() {
        if (!this.showForm) {
            this.loadButtons()
            var update = this.$route.params.update
            var config_id = this.$route.params.config_id
            log('printer config - update:', update)
            log('printer config - config_id:', config_id)
            if (update && config_id != undefined) {
                this.updateConfig(config_id)
                this.$overlay.hide()
                this.ready = true
            }
            // VALIDATE
            else {
                var self = this
                this.$store.dispatch('printer/loadPrinterInfo', {}).then(() => {
                    log('FINISH')
                    self.ready = true
                    self.$overlay.hide()

                    // WINDOWS
                    if (!self.isApp) {
                        if (!self.isValidatebPac) {
                            self.validateBpac(self.currentPrinter() ? self.currentPrinter() : false)
                        }
                    }
                    // APP
                    else {
                        self.validatePrinter()
                    }
                })
            }
        }
    },
    destroyed() {
        this.$bar.reset()
        // ENSURE STOP LISTENING CLICKS WHEN IS DESTROYED
        document.removeEventListener('click', this.toggleBoxes)
    },

    watch: {
        showForm(newVal, oldVal) {
            if (newVal) {
                this.$bar.reset()
                document.removeEventListener('click', this.toggleBoxes)
            } else {
                document.addEventListener('click', this.toggleBoxes)
                this.loadButtons()
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#content.config {
    padding-bottom: 50px;
    .printer {
        &:last-child {
            padding-bottom: 70px;
        }

        .description {
            font-family: $text;
            text-align: center;
            color: $main-text;
            width: 100%;
            margin: 10px 0;
            font-family: $text-bold;
            color: #333;
            @include font-size(xl);
            margin-top: 50px;
            line-height: 45px;
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#content.config.portrait {
    height: calc(100vh - 60px) !important;
    padding: 10px;

    .printer {
        .description {
            @include font-size(m);
            margin-top: 5px;
            margin-bottom: 5px;
            line-height: 35px;
            color: #000;
        }
    }
}
</style>
