<template>
  <div class="device">
    <!-- <h3 class="title lines">{{ $t('config.device.title') }}</h3> -->
    <div class="warning">
      <h6 class="titl">{{ $t("config.device.warning_title") }}</h6>
      <span class="text">{{ $t("config.device.warning_desc") }}</span>
      <button @click="goOut" class="unlink">
        {{ $t("config.device.unlink") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {
    goOut() {
        var self = this
        this.$store.dispatch( 'login/logout' ).then( function(){
            if (false && process.env.VUE_APP_IS_APP == "TRUE") {
                //CLEAN FOLDER WITH ALEX FILE PRINTER CONFIGURATION
                window.resolveLocalFileSystemURL(
                    cordova.file.externalRootDirectory + "ALEX",
                    function(fileSystem) {
                        var reader = fileSystem.createReader();
                        reader.readEntries(
                            function(entries) {
                                entries.forEach(entry => {
                                    entry.remove(
                                        function() {},
                                        function(error) {}
                                    );
                                });
                                self.$router.push("/login").then(function() {
                                    location.reload();
                                });
                            },
                            function(err) {
                                log(err);
                            }
                        );
                    },
                    function(err) {
                        log(err);
                    }
                );
            } else {
                self.$router.push("/login").then(function() {
                    location.reload();
                });
            }
        })
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.config {
  .device {
    .warning {
      @include background(
        $image: img("alert_complem.svg"),
        $position: top 12px center,
        $size: 80px
      );
      @include border-radius(3px);
      padding: 100px 12px 12px 12px;
      margin: 24px;

      .titl {
        @include font-size(l);
        display: block;
        padding: 12px;
        margin: 0 auto;
        text-align: center;
        color: $main-text;
        font-family: $text-medium;
      }
      .text {
        @include font-size(m);
        display: block;
        padding: 12px;
        text-align: center;
        color: $main-text;
        font-family: $text;
      }

      .unlink {
        @include font-size(ml);
        @include border-radius(5px);
        font-family: $text-medium;
        padding: 15px;
        background-color: $accent;
        color: $accent-text;
        width: 60%;
        margin: 24px auto;
        text-align: center;
        display: block;
      }
    }
  }
}
</style>
