<template>
    <div class="dev">
        <section class="color">
            <h4>Variables de color</h4>

            <h5>Main</h5>
            <div class="main">
                <span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span>
                <div class="light"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="dark"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
            </div>

            <h5>Neutro</h5>
            <div class="neutro">
                <span class="tag"></span><span class="tag"></span><span class="tag"></span>
                <div class="light"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="dark"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
            </div>

            <h5>Accent/Complementario</h5>
            <div class="accent">
                <span class="tag"></span><span class="tag"></span><span class="tag"></span>
                <div class="light"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="dark"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
            </div>

            <h4>Colores semáforo</h4>
            <h5>Inactive</h5>
            <div class="inactive">
                <span class="tag"></span><span class="tag"></span><span class="tag"></span>
                <div class="light"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="dark"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
            </div>

            <h5>Error</h5>
            <div class="error">
                <span class="tag"></span><span class="tag"></span><span class="tag"></span>
                <div class="light"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="dark"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
            </div>

            <h5>Warning</h5>
            <div class="warning">
                <span class="tag"></span><span class="tag"></span><span class="tag"></span>
                <div class="light"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="dark"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
            </div>

            <h5>Progress</h5>
            <div class="progress">
                <span class="tag"></span><span class="tag"></span><span class="tag"></span>
                <div class="light"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="dark"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
            </div>

            <h5>Done</h5>
            <div class="done">
                <span class="tag"></span><span class="tag"></span><span class="tag"></span>
                <div class="light"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="dark"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
            </div>

            <h5>Info</h5>
            <div class="info">
                <span class="tag"></span><span class="tag"></span><span class="tag"></span>
                <div class="light"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="dark"><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
            </div>

            <h4>Colores base</h4>
            <h5>BGS</h5>
            <div class="base"><span class="tag"></span><span class="tag"></span></div>

            <h5>"Monocolor"</h5>
            <div class="mono">
                <div class="m1"><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="m2"><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="m3"><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="m4"><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="m5"><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="m6"><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="m7"><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="m8"><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
                <div class="m9"><span class="tag"></span><span class="tag"></span><span class="tag"></span></div>
            </div>
        </section>

        <section class="fonts">
            <h4>Tamaños de la fuente de letra</h4>
            <ul class="font-sizes">
                <li>Bom Trabalho! 1</li>
                <li>Bom Trabalho! 2</li>
                <li>Bom Trabalho! 3</li>
                <li>Bom Trabalho! 4</li>
                <li>Bom Trabalho! 5</li>
                <li>Bom Trabalho! 6</li>
                <li>Bom Trabalho! 7</li>
                <li>Bom Trabalho! unset</li>
                <li>Bom Trabalho! inherit</li>
                <li>Bom Trabalho! custom (32px)</li>
                <li>Bom Trabalho! custom (4rem)</li>
            </ul>
            <ul class="font-sizes-upper">
                <li>Bom Trabalho! 1</li>
                <li>Bom Trabalho! 2</li>
                <li>Bom Trabalho! 3</li>
                <li>Bom Trabalho! 4</li>
                <li>Bom Trabalho! 5</li>
                <li>Bom Trabalho! 6</li>
                <li>Bom Trabalho! 7</li>
                <li>Bom Trabalho! unset</li>
                <li>Bom Trabalho! inherit</li>
                <li>Bom Trabalho! custom (32px)</li>
                <li>Bom Trabalho! custom (4rem)</li>
            </ul>
            <ul class="font-sizes2">
                <li>Bom Trabalho! 1</li>
                <li>Bom Trabalho! 2</li>
                <li>Bom Trabalho! 3</li>
                <li>Bom Trabalho! 4</li>
                <li>Bom Trabalho! 5</li>
                <li>Bom Trabalho! 6</li>
                <li>Bom Trabalho! 7</li>
                <li>Bom Trabalho! unset</li>
                <li>Bom Trabalho! inherit</li>
                <li>Bom Trabalho! custom (32px)</li>
                <li>Bom Trabalho! custom (4rem)</li>
            </ul>
            <ul class="font-sizes2-upper">
                <li>Bom Trabalho! 1</li>
                <li>Bom Trabalho! 2</li>
                <li>Bom Trabalho! 3</li>
                <li>Bom Trabalho! 4</li>
                <li>Bom Trabalho! 5</li>
                <li>Bom Trabalho! 6</li>
                <li>Bom Trabalho! 7</li>
                <li>Bom Trabalho! unset</li>
                <li>Bom Trabalho! inherit</li>
                <li>Bom Trabalho! custom (32px)</li>
                <li>Bom Trabalho! custom (4rem)</li>
            </ul>
        </section>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    computed: {},
    methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.config {
    .dev {
        .color {
            .tag {
                display: inline-block;
                width: 60px;
                height: 60px;
            }

            .main {
                > .tag:nth-child(1) {
                    background-color: $main-light;
                }
                > .tag:nth-child(2) {
                    background-color: $main;
                }
                > .tag:nth-child(3) {
                    background-color: $main-dark;
                }
                > .tag:nth-child(4) {
                    background-color: $main-text;
                }

                > .light {
                    > .tag:nth-child(1) {
                        background-color: $main-t90;
                    }
                    > .tag:nth-child(2) {
                        background-color: $main-t80;
                    }
                    > .tag:nth-child(3) {
                        background-color: $main-t70;
                    }
                    > .tag:nth-child(4) {
                        background-color: $main-t60;
                    }
                    > .tag:nth-child(5) {
                        background-color: $main-t50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $main-t40;
                    }
                    > .tag:nth-child(7) {
                        background-color: $main-t30;
                    }
                    > .tag:nth-child(8) {
                        background-color: $main-t20;
                    }
                    > .tag:nth-child(9) {
                        background-color: $main-t10;
                    }
                }
                > .dark {
                    > .tag:nth-child(1) {
                        background-color: $main-s10;
                    }
                    > .tag:nth-child(2) {
                        background-color: $main-s20;
                    }
                    > .tag:nth-child(3) {
                        background-color: $main-s30;
                    }
                    > .tag:nth-child(4) {
                        background-color: $main-s40;
                    }
                    > .tag:nth-child(5) {
                        background-color: $main-s50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $main-s60;
                    }
                    > .tag:nth-child(7) {
                        background-color: $main-s70;
                    }
                    > .tag:nth-child(8) {
                        background-color: $main-s80;
                    }
                    > .tag:nth-child(9) {
                        background-color: $main-s90;
                    }
                }
            }
            .neutro {
                > .tag:nth-child(1) {
                    background-color: $neutro-light;
                }
                > .tag:nth-child(2) {
                    background-color: $neutro;
                }
                > .tag:nth-child(3) {
                    background-color: $neutro-dark;
                }
                > .tag:nth-child(4) {
                    background-color: $neutro-text;
                }

                > .light {
                    > .tag:nth-child(1) {
                        background-color: $neutro-t90;
                    }
                    > .tag:nth-child(2) {
                        background-color: $neutro-t80;
                    }
                    > .tag:nth-child(3) {
                        background-color: $neutro-t70;
                    }
                    > .tag:nth-child(4) {
                        background-color: $neutro-t60;
                    }
                    > .tag:nth-child(5) {
                        background-color: $neutro-t50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $neutro-t40;
                    }
                    > .tag:nth-child(7) {
                        background-color: $neutro-t30;
                    }
                    > .tag:nth-child(8) {
                        background-color: $neutro-t20;
                    }
                    > .tag:nth-child(9) {
                        background-color: $neutro-t10;
                    }
                }
                > .dark {
                    > .tag:nth-child(1) {
                        background-color: $neutro-s10;
                    }
                    > .tag:nth-child(2) {
                        background-color: $neutro-s20;
                    }
                    > .tag:nth-child(3) {
                        background-color: $neutro-s30;
                    }
                    > .tag:nth-child(4) {
                        background-color: $neutro-s40;
                    }
                    > .tag:nth-child(5) {
                        background-color: $neutro-s50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $neutro-s60;
                    }
                    > .tag:nth-child(7) {
                        background-color: $neutro-s70;
                    }
                    > .tag:nth-child(8) {
                        background-color: $neutro-s80;
                    }
                    > .tag:nth-child(9) {
                        background-color: $neutro-s90;
                    }
                }
            }
            .accent {
                > .tag:nth-child(1) {
                    background-color: $accent-light;
                }
                > .tag:nth-child(2) {
                    background-color: $accent;
                }
                > .tag:nth-child(3) {
                    background-color: $accent-dark;
                }
                > .tag:nth-child(4) {
                    background-color: $accent-text;
                }

                > .light {
                    > .tag:nth-child(1) {
                        background-color: $accent-t90;
                    }
                    > .tag:nth-child(2) {
                        background-color: $accent-t80;
                    }
                    > .tag:nth-child(3) {
                        background-color: $accent-t70;
                    }
                    > .tag:nth-child(4) {
                        background-color: $accent-t60;
                    }
                    > .tag:nth-child(5) {
                        background-color: $accent-t50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $accent-t40;
                    }
                    > .tag:nth-child(7) {
                        background-color: $accent-t30;
                    }
                    > .tag:nth-child(8) {
                        background-color: $accent-t20;
                    }
                    > .tag:nth-child(9) {
                        background-color: $accent-t10;
                    }
                }
                > .dark {
                    > .tag:nth-child(1) {
                        background-color: $accent-s10;
                    }
                    > .tag:nth-child(2) {
                        background-color: $accent-s20;
                    }
                    > .tag:nth-child(3) {
                        background-color: $accent-s30;
                    }
                    > .tag:nth-child(4) {
                        background-color: $accent-s40;
                    }
                    > .tag:nth-child(5) {
                        background-color: $accent-s50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $accent-s60;
                    }
                    > .tag:nth-child(7) {
                        background-color: $accent-s70;
                    }
                    > .tag:nth-child(8) {
                        background-color: $accent-s80;
                    }
                    > .tag:nth-child(9) {
                        background-color: $accent-s90;
                    }
                }
            }
            .inactive {
                > .tag:nth-child(1) {
                    background-color: $inactive-light;
                }
                > .tag:nth-child(2) {
                    background-color: $inactive;
                }
                > .tag:nth-child(3) {
                    background-color: $inactive-dark;
                }
                > .tag:nth-child(4) {
                    background-color: $inactive-text;
                }

                > .light {
                    > .tag:nth-child(1) {
                        background-color: $inactive-t90;
                    }
                    > .tag:nth-child(2) {
                        background-color: $inactive-t80;
                    }
                    > .tag:nth-child(3) {
                        background-color: $inactive-t70;
                    }
                    > .tag:nth-child(4) {
                        background-color: $inactive-t60;
                    }
                    > .tag:nth-child(5) {
                        background-color: $inactive-t50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $inactive-t40;
                    }
                    > .tag:nth-child(7) {
                        background-color: $inactive-t30;
                    }
                    > .tag:nth-child(8) {
                        background-color: $inactive-t20;
                    }
                    > .tag:nth-child(9) {
                        background-color: $inactive-t10;
                    }
                }
                > .dark {
                    > .tag:nth-child(1) {
                        background-color: $inactive-s10;
                    }
                    > .tag:nth-child(2) {
                        background-color: $inactive-s20;
                    }
                    > .tag:nth-child(3) {
                        background-color: $inactive-s30;
                    }
                    > .tag:nth-child(4) {
                        background-color: $inactive-s40;
                    }
                    > .tag:nth-child(5) {
                        background-color: $inactive-s50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $inactive-s60;
                    }
                    > .tag:nth-child(7) {
                        background-color: $inactive-s70;
                    }
                    > .tag:nth-child(8) {
                        background-color: $inactive-s80;
                    }
                    > .tag:nth-child(9) {
                        background-color: $inactive-s90;
                    }
                }
            }
            .error {
                > .tag:nth-child(1) {
                    background-color: $error-light;
                }
                > .tag:nth-child(2) {
                    background-color: $error;
                }
                > .tag:nth-child(3) {
                    background-color: $error-dark;
                }
                > .tag:nth-child(4) {
                    background-color: $error-text;
                }

                > .light {
                    > .tag:nth-child(1) {
                        background-color: $error-t90;
                    }
                    > .tag:nth-child(2) {
                        background-color: $error-t80;
                    }
                    > .tag:nth-child(3) {
                        background-color: $error-t70;
                    }
                    > .tag:nth-child(4) {
                        background-color: $error-t60;
                    }
                    > .tag:nth-child(5) {
                        background-color: $error-t50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $error-t40;
                    }
                    > .tag:nth-child(7) {
                        background-color: $error-t30;
                    }
                    > .tag:nth-child(8) {
                        background-color: $error-t20;
                    }
                    > .tag:nth-child(9) {
                        background-color: $error-t10;
                    }
                }
                > .dark {
                    > .tag:nth-child(1) {
                        background-color: $error-s10;
                    }
                    > .tag:nth-child(2) {
                        background-color: $error-s20;
                    }
                    > .tag:nth-child(3) {
                        background-color: $error-s30;
                    }
                    > .tag:nth-child(4) {
                        background-color: $error-s40;
                    }
                    > .tag:nth-child(5) {
                        background-color: $error-s50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $error-s60;
                    }
                    > .tag:nth-child(7) {
                        background-color: $error-s70;
                    }
                    > .tag:nth-child(8) {
                        background-color: $error-s80;
                    }
                    > .tag:nth-child(9) {
                        background-color: $error-s90;
                    }
                }
            }
            .warning {
                > .tag:nth-child(1) {
                    background-color: $warning-light;
                }
                > .tag:nth-child(2) {
                    background-color: $warning;
                }
                > .tag:nth-child(3) {
                    background-color: $warning-dark;
                }
                > .tag:nth-child(4) {
                    background-color: $warning-text;
                }

                > .light {
                    > .tag:nth-child(1) {
                        background-color: $warning-t90;
                    }
                    > .tag:nth-child(2) {
                        background-color: $warning-t80;
                    }
                    > .tag:nth-child(3) {
                        background-color: $warning-t70;
                    }
                    > .tag:nth-child(4) {
                        background-color: $warning-t60;
                    }
                    > .tag:nth-child(5) {
                        background-color: $warning-t50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $warning-t40;
                    }
                    > .tag:nth-child(7) {
                        background-color: $warning-t30;
                    }
                    > .tag:nth-child(8) {
                        background-color: $warning-t20;
                    }
                    > .tag:nth-child(9) {
                        background-color: $warning-t10;
                    }
                }
                > .dark {
                    > .tag:nth-child(1) {
                        background-color: $warning-s10;
                    }
                    > .tag:nth-child(2) {
                        background-color: $warning-s20;
                    }
                    > .tag:nth-child(3) {
                        background-color: $warning-s30;
                    }
                    > .tag:nth-child(4) {
                        background-color: $warning-s40;
                    }
                    > .tag:nth-child(5) {
                        background-color: $warning-s50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $warning-s60;
                    }
                    > .tag:nth-child(7) {
                        background-color: $warning-s70;
                    }
                    > .tag:nth-child(8) {
                        background-color: $warning-s80;
                    }
                    > .tag:nth-child(9) {
                        background-color: $warning-s90;
                    }
                }
            }
            .progress {
                > .tag:nth-child(1) {
                    background-color: $progress-light;
                }
                > .tag:nth-child(2) {
                    background-color: $progress;
                }
                > .tag:nth-child(3) {
                    background-color: $progress-dark;
                }
                > .tag:nth-child(4) {
                    background-color: $progress-text;
                }

                > .light {
                    > .tag:nth-child(1) {
                        background-color: $progress-t90;
                    }
                    > .tag:nth-child(2) {
                        background-color: $progress-t80;
                    }
                    > .tag:nth-child(3) {
                        background-color: $progress-t70;
                    }
                    > .tag:nth-child(4) {
                        background-color: $progress-t60;
                    }
                    > .tag:nth-child(5) {
                        background-color: $progress-t50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $progress-t40;
                    }
                    > .tag:nth-child(7) {
                        background-color: $progress-t30;
                    }
                    > .tag:nth-child(8) {
                        background-color: $progress-t20;
                    }
                    > .tag:nth-child(9) {
                        background-color: $progress-t10;
                    }
                }
                > .dark {
                    > .tag:nth-child(1) {
                        background-color: $progress-s10;
                    }
                    > .tag:nth-child(2) {
                        background-color: $progress-s20;
                    }
                    > .tag:nth-child(3) {
                        background-color: $progress-s30;
                    }
                    > .tag:nth-child(4) {
                        background-color: $progress-s40;
                    }
                    > .tag:nth-child(5) {
                        background-color: $progress-s50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $progress-s60;
                    }
                    > .tag:nth-child(7) {
                        background-color: $progress-s70;
                    }
                    > .tag:nth-child(8) {
                        background-color: $progress-s80;
                    }
                    > .tag:nth-child(9) {
                        background-color: $progress-s90;
                    }
                }
            }
            .done {
                > .tag:nth-child(1) {
                    background-color: $done-light;
                }
                > .tag:nth-child(2) {
                    background-color: $done;
                }
                > .tag:nth-child(3) {
                    background-color: $done-dark;
                }
                > .tag:nth-child(4) {
                    background-color: $done-text;
                }

                > .light {
                    > .tag:nth-child(1) {
                        background-color: $done-t90;
                    }
                    > .tag:nth-child(2) {
                        background-color: $done-t80;
                    }
                    > .tag:nth-child(3) {
                        background-color: $done-t70;
                    }
                    > .tag:nth-child(4) {
                        background-color: $done-t60;
                    }
                    > .tag:nth-child(5) {
                        background-color: $done-t50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $done-t40;
                    }
                    > .tag:nth-child(7) {
                        background-color: $done-t30;
                    }
                    > .tag:nth-child(8) {
                        background-color: $done-t20;
                    }
                    > .tag:nth-child(9) {
                        background-color: $done-t10;
                    }
                }
                > .dark {
                    > .tag:nth-child(1) {
                        background-color: $done-s10;
                    }
                    > .tag:nth-child(2) {
                        background-color: $done-s20;
                    }
                    > .tag:nth-child(3) {
                        background-color: $done-s30;
                    }
                    > .tag:nth-child(4) {
                        background-color: $done-s40;
                    }
                    > .tag:nth-child(5) {
                        background-color: $done-s50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $done-s60;
                    }
                    > .tag:nth-child(7) {
                        background-color: $done-s70;
                    }
                    > .tag:nth-child(8) {
                        background-color: $done-s80;
                    }
                    > .tag:nth-child(9) {
                        background-color: $done-s90;
                    }
                }
            }
            .info {
                > .tag:nth-child(1) {
                    background-color: $info-light;
                }
                > .tag:nth-child(2) {
                    background-color: $info;
                }
                > .tag:nth-child(3) {
                    background-color: $info-dark;
                }
                > .tag:nth-child(4) {
                    background-color: $info-text;
                }

                > .light {
                    > .tag:nth-child(1) {
                        background-color: $info-t90;
                    }
                    > .tag:nth-child(2) {
                        background-color: $info-t80;
                    }
                    > .tag:nth-child(3) {
                        background-color: $info-t70;
                    }
                    > .tag:nth-child(4) {
                        background-color: $info-t60;
                    }
                    > .tag:nth-child(5) {
                        background-color: $info-t50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $info-t40;
                    }
                    > .tag:nth-child(7) {
                        background-color: $info-t30;
                    }
                    > .tag:nth-child(8) {
                        background-color: $info-t20;
                    }
                    > .tag:nth-child(9) {
                        background-color: $info-t10;
                    }
                }
                > .dark {
                    > .tag:nth-child(1) {
                        background-color: $info-s10;
                    }
                    > .tag:nth-child(2) {
                        background-color: $info-s20;
                    }
                    > .tag:nth-child(3) {
                        background-color: $info-s30;
                    }
                    > .tag:nth-child(4) {
                        background-color: $info-s40;
                    }
                    > .tag:nth-child(5) {
                        background-color: $info-s50;
                    }
                    > .tag:nth-child(6) {
                        background-color: $info-s60;
                    }
                    > .tag:nth-child(7) {
                        background-color: $info-s70;
                    }
                    > .tag:nth-child(8) {
                        background-color: $info-s80;
                    }
                    > .tag:nth-child(9) {
                        background-color: $info-s90;
                    }
                }
            }

            .base {
                > .tag {
                    border: 1px solid #ccc;
                }
                > .tag:nth-child(1) {
                    background-color: $default-bg-color;
                }
                > .tag:nth-child(2) {
                    background-color: $default-bg-color;
                }
            }

            .mono {
                > .m1 {
                    > .tag:nth-child(1) {
                        background-color: $mono-1-light;
                    }
                    > .tag:nth-child(2) {
                        background-color: $mono-1;
                    }
                    > .tag:nth-child(3) {
                        background-color: $mono-1-dark;
                    }
                }
                > .m2 {
                    > .tag:nth-child(1) {
                        background-color: $mono-2-light;
                    }
                    > .tag:nth-child(2) {
                        background-color: $mono-2;
                    }
                    > .tag:nth-child(3) {
                        background-color: $mono-2-dark;
                    }
                }
                > .m3 {
                    > .tag:nth-child(1) {
                        background-color: $mono-3-light;
                    }
                    > .tag:nth-child(2) {
                        background-color: $mono-3;
                    }
                    > .tag:nth-child(3) {
                        background-color: $mono-3-dark;
                    }
                }
                > .m4 {
                    > .tag:nth-child(1) {
                        background-color: $mono-4-light;
                    }
                    > .tag:nth-child(2) {
                        background-color: $mono-4;
                    }
                    > .tag:nth-child(3) {
                        background-color: $mono-4-dark;
                    }
                }
                > .m5 {
                    > .tag:nth-child(1) {
                        background-color: $mono-5-light;
                    }
                    > .tag:nth-child(2) {
                        background-color: $mono-5;
                    }
                    > .tag:nth-child(3) {
                        background-color: $mono-5-dark;
                    }
                }
                > .m6 {
                    > .tag:nth-child(1) {
                        background-color: $mono-6-light;
                    }
                    > .tag:nth-child(2) {
                        background-color: $mono-6;
                    }
                    > .tag:nth-child(3) {
                        background-color: $mono-6-dark;
                    }
                }
                > .m7 {
                    > .tag:nth-child(1) {
                        background-color: $mono-7-light;
                    }
                    > .tag:nth-child(2) {
                        background-color: $mono-7;
                    }
                    > .tag:nth-child(3) {
                        background-color: $mono-7-dark;
                    }
                }
                > .m8 {
                    > .tag:nth-child(1) {
                        background-color: $mono-8-light;
                    }
                    > .tag:nth-child(2) {
                        background-color: $mono-8;
                    }
                    > .tag:nth-child(3) {
                        background-color: $mono-8-dark;
                    }
                }
                > .m9 {
                    > .tag:nth-child(1) {
                        background-color: $mono-9-light;
                    }
                    > .tag:nth-child(2) {
                        background-color: $mono-9;
                    }
                    > .tag:nth-child(3) {
                        background-color: $mono-9-dark;
                    }
                }
            }
        }

        .fonts {
            .font-sizes,
            .font-sizes-upper {
                display: inline-block;
                width: 48%;

                li {
                    font-family: $text;
                }

                li:nth-child(1) {
                    @include font-size(xs);
                }
                li:nth-child(2) {
                    @include font-size(s);
                }
                li:nth-child(3) {
                    @include font-size(sm);
                }
                li:nth-child(4) {
                    @include font-size(m);
                }
                li:nth-child(5) {
                    @include font-size(ml);
                }
                li:nth-child(6) {
                    @include font-size(l);
                }
                li:nth-child(7) {
                    @include font-size(xl);
                }
                li:nth-child(8) {
                    @include font-size(unset);
                }
                li:nth-child(9) {
                    @include font-size(inherit);
                }
                li:nth-child(10) {
                    @include font-size(32px);
                }
                li:nth-child(11) {
                    @include font-size(4rem);
                }
            }
            .font-sizes-upper {
                margin-bottom: 24px;

                li {
                    text-transform: uppercase;
                }
            }

            .font-sizes2,
            .font-sizes2-upper {
                display: inline-block;
                width: 48%;

                li {
                    font-family: $conden;
                }

                li:nth-child(1) {
                    @include font-size(xs);
                }
                li:nth-child(2) {
                    @include font-size(s);
                }
                li:nth-child(3) {
                    @include font-size(sm);
                }
                li:nth-child(4) {
                    @include font-size(m);
                }
                li:nth-child(5) {
                    @include font-size(ml);
                }
                li:nth-child(6) {
                    @include font-size(l);
                }
                li:nth-child(7) {
                    @include font-size(xl);
                }
                li:nth-child(8) {
                    @include font-size(unset);
                }
                li:nth-child(9) {
                    @include font-size(inherit);
                }
                li:nth-child(10) {
                    @include font-size(32px);
                }
                li:nth-child(11) {
                    @include font-size(4rem);
                }
            }
            .font-sizes2-upper {
                margin-bottom: 24px;

                li {
                    text-transform: uppercase;
                }
            }
        }
    }
}
</style>
