<template>
    <div class="location-container" :class="[$mq]">
        <div class="description">
            <span class="normal">{{ $t('config.location.description') + ' ' }}</span>
            <span class="bold">{{ location.name }}</span>
        </div>

        <div class="config-element account">
            <div class="config-title">{{ $t('config.location.account_information') }}</div>
            <div class="element">
                <div class="key">{{ $t('config.location.identification') }}</div>
                <div class="value">{{ location.identification }}</div>
            </div>
            <div class="element">
                <div class="key">{{ $t('config.location.username') }}</div>
                <div class="value">{{ location.username }}</div>
            </div>
        </div>

        <div class="config-element language">
            <div class="config-title">{{ $t('config.language.description') }}</div>
            <div class="select">
                <div v-for="lang in languages" class="btn" :class="{ selected: lang == language }" :key="lang" @click="changeLang(lang)">{{ $t('assets.languages.' + lang) }}</div>
            </div>
        </div>

        <div class="config-element sound">
            <div class="config-title">{{ $t('config.sound.description') }}</div>
            <div class="select">
                <div class="btn" :class="{ selected: sound }" @click="toggleSound()">{{ sound ? $t('config.sound.on') : $t('config.sound.off') }}</div>
            </div>
        </div>

        <div class="config-element timezone">
            <div class="config-title">{{ $t('config.location.timezones') }}</div>
            <div class="element">
                <div class="key">{{ timeZoneName }}</div>
            </div>
        </div>

        <div class="btn unlink" @click="goOut">{{ $t('config.location.unlink') }}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            test: {},
            lang: ''
        }
    },
    created() {
        this.test = this.$store.getters['login/getLocation']
        this.$store.dispatch('account/loadInformation')
    },
    computed: {
        language() {
            return this.$store.getters['getLanguage']
        },
        languages() {
            return this.$store.getters['getLanguages']
        },
        location() {
            return Object.keys(this.$store.getters['loginUser/getLocalLocation']) > 0 ? this.$store.getters['loginUser/getLocalLocation'] : this.$store.getters['login/getLocation']
        },
        token() {
            return this.$store.getters['loginUser/getLocalToken']
        },
        timezones() {
            return this.$store.getters['account/getTimeZones']
        },
        timezone() {
            return this.location.timezone
        },
        timeZoneName() {
            return this.$store.getters['getTimeZoneName']
        },
        sound() {
            return this.$store.getters['getSound']
        }
    },
    methods: {
        toggleSound() {
            if (this.sound) {
                this.$store.commit('setSound', false, { root: true })
            } else {
                this.$store.commit('setSound', true, { root: true })
                this.$store.dispatch('playSound', 'empty.wav')
            }
        },

        changeLang(lang) {
            this.$store.commit('changeLanguage', lang)

            switch (lang) {
                case 'es':
                    this.lang = 'es_ES'
                    break
                case 'en':
                    this.lang = 'en_US'
                    break
                case 'pt':
                    this.lang = 'pt_PT'
                    break
                case 'de':
                    this.lang = 'de_DE'
                    break
            }

            this.updateInformation()
        },

        updateInformation() {
            this.$store.dispatch('account/updateInformation', { lang: this.lang })
        },

        changeTimeZone(event) {},

        goOut() {
            var self = this
            this.$popup.confirm({
                message: this.$t('config.device.warning_desc'),
                textSave: this.$t('config.location.unlink_confirm'),
                callSave: function() {
                    self.$store.dispatch('login/logout').then(function() {
                        if (false && process.env.VUE_APP_IS_APP == 'TRUE') {
                            //CLEAN FOLDER WITH ALEX FILE PRINTER CONFIGURATION
                            window.resolveLocalFileSystemURL(
                                cordova.file.externalRootDirectory + 'ALEX',
                                function(fileSystem) {
                                    var reader = fileSystem.createReader()
                                    reader.readEntries(
                                        function(entries) {
                                            entries.forEach(entry => {
                                                entry.remove(
                                                    function() {},
                                                    function(error) {}
                                                )
                                            })
                                            self.$router.push('/login').then(function() {
                                                location.reload()
                                            })
                                        },
                                        function(err) {
                                            log(err)
                                        }
                                    )
                                },
                                function(err) {
                                    log(err)
                                }
                            )
                        } else {
                            self.$router.push('/login').then(function() {
                                location.reload()
                            })
                        }
                    })
                }
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.location-container {
    padding: 40px 40px 0 40px;
    width: auto;
    max-width: 800px;

    .description {
        .normal {
            @include font-size(xl);
            font-family: $text-medium;
            color: $neutro-s90;
            line-height: 1.25;
        }

        .bold {
            @include font-size(xl);
            font-family: $text-bold;
            color: $neutro-s90;
            line-height: 1.25;
        }
    }

    .config-element {
        @include border-radius(6px);
        background-color: #fff;
        margin: 30px 0;
        padding: 15px 30px;
        height: auto;

        .config-title {
            @include font-size(ml);
            font-family: $text-bold;
            color: $neutro-s90;
            margin-bottom: 15px;
        }

        .element {
            min-height: 30px;
            height: auto;

            .key {
                @include font-size(m);
                font-family: $text-medium;
                color: $neutro-s90;
                display: inline-block;
                width: 170px;
                padding-right: 12px;
            }

            .value {
                @include font-size(m);
                font-family: $text-bold;
                color: $neutro-s90;
                display: inline-block;
            }
        }

        &.language {
            .select {
                @include display-flex();
                @include align-items(flex-start);
                @include justify-content(flex-start);
                @include flex-wrap(wrap);
                height: auto;
            }

            .btn {
                font-family: $text-medium;
                color: $main-s10;
                background-color: $neutro;
                margin: 6px;

                &:hover {
                    background-color: hover($neutro);
                }

                &.selected {
                    font-family: $text-bold;
                    color: #fff;
                    background-color: $main;

                    &:hover {
                        background-color: hover($main);
                    }
                }
            }
        }

        &.sound {
            .select {
                .btn {
                    font-family: $text-medium;
                    color: $main-s10;
                    background-color: $neutro;
                    margin: 6px;
                    width: fit-content;
                    padding: 0 10px 0 40px;
                    @include background($image: img('sound_off.svg'), $position: center left 15px, $size: 15px);
                    line-height: 45px;
                    min-height: 45px;

                    &:hover {
                        background-color: hover($neutro);
                    }

                    &.selected {
                        font-family: $text-bold;
                        color: #fff;
                        background-color: $main;
                        @include background($image: img('sound_on.svg'), $position: center left 15px, $size: 17px);

                        &:hover {
                            background-color: hover($main);
                        }
                    }
                }
            }
        }

        &.timezone {
            select {
                @include border-radius(4px);
                @include font-size(m);
                @include background($image: img('down_caret_neutro_s90.svg'), $position: center right 15px, $size: 10px);
                font-family: $text-medium;
                color: $neutro-s90;
                background-color: $neutro-t50;
                border: none;
                cursor: not-allowed;
            }
        }
    }

    .unlink {
        background-color: $warning;
        width: 230px;
        text-align: center;

        &:hover {
            background-color: hover($warning);
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.location-container.portrait {
    padding: 5px;

    .description {
        text-align: center;
        .normal {
            @include font-size(sm);
        }
        .bold {
            @include font-size(sm);
        }
    }

    .config-element {
        margin: 15px 0;
        padding: 15px;
        .config-title {
            @include font-size(s);
            line-height: 18px;
            margin-bottom: 10px;
        }

        .element {
            .key {
                @include font-size(s);
                width: 50%;
                padding-right: 12px;
                line-height: 16px;
            }

            .value {
                @include font-size(s);
                font-family: $text-bold;
                line-height: 16px;
            }
        }

        &.language {
            .btn {
                margin: 3px;
                @include font-size(xs);
                min-height: 25px;
                padding: 5px 10px;

                &:hover {
                    background-color: hover($neutro);
                }

                &.selected {
                    font-family: $text-bold;
                    color: #fff;
                    background-color: $main;

                    &:hover {
                        background-color: hover($main);
                    }
                }
            }
        }

        &.sound {
            .btn {
                margin: 3px;
                @include font-size(xs);
                min-height: 25px;
                line-height: 40px;
            }
        }
    }
    .unlink {
        width: 250px;
        @include font-size(s);
        margin: 0 auto;
    }
}
</style>
